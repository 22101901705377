import { ChildrenReactNode } from "lib/global";
import React from "react";
import { useMediaQuery } from "react-responsive";

const Mobile = ({ children }: ChildrenReactNode) => {
  // tablet
  //  query: '(min-width:768px) and (max-width:1023px)',
  const isMobile = useMediaQuery({
    query: "(max-width:1099px)",
  });
  return <>{isMobile && children}</>;
};

const PC = ({ children }: ChildrenReactNode) => {
  const isPc = useMediaQuery({
    query: "(min-width:1100px) ",
  });
  return <>{isPc && children}</>;
};

export { Mobile, PC };
